import dupeBusinessClient from "./DupeBusinessClient";

export async function createCampaign(campaign) {
    return await dupeBusinessClient.post("/api/v1/campaigns", campaign).catch(error => {
        console.log(error);
    });
}

export async function getCampaigns() {
    return await dupeBusinessClient.get("/api/v1/campaigns").catch(error => {
        console.log(error);
    });
}

export async function getArchivedCampaigns() {
    return await dupeBusinessClient.get("/api/v1/campaigns/archived").catch(error => {
        console.log(error);
    });
}

export async function getCampaignByName(campaign) {
    return await dupeBusinessClient.get("/api/v1/campaigns/" + campaign).catch(error => {
        console.log(error);
    });
}

export async function createCampaignCreator(request) {
    return await dupeBusinessClient.post("/api/v1/campaigns/creators", request).catch(error => {
        console.log(error);
    });
}

export async function getCampaignCreators(campaignId) {
    return await dupeBusinessClient.get("/api/v1/campaigns/" + campaignId + "/creators").catch(error => {
        console.log(error);
    });
}

export async function createCampaignImage(campaignId, request) {
    return await dupeBusinessClient.post("/api/v1/campaigns/" + campaignId +"/image", request, { headers: {'Content-Type': 'multipart/form-data'}}).catch(error => {
        console.log(error);
    });
}

export async function updateCampaignCreator(campaignId, request) {
    return await dupeBusinessClient.put("/api/v1/campaigns/" + campaignId + "/creators", request).catch(error => {
        console.log(error);
    });
}

export async function denyCampaignCreator(campaignId, creatorId) {
    return await dupeBusinessClient.get("/api/v1/campaigns/" + campaignId + "/creators/" + creatorId + "/deny").catch(error => {
        console.log(error);
    });
}

export async function createCampaignAttachment(campaignId, request) {
    return await dupeBusinessClient.post("/api/v1/campaigns/" + campaignId + "/attachment", request, { headers: {'Content-Type': 'multipart/form-data'}}).catch(error => {
        console.log(error);
    });
}

export async function getContentHub() {
    return await dupeBusinessClient.get("/api/v1/campaigns/content/summary").catch(error => {
        console.log(error);
    });
}

export async function getCampaignSlug(campaignId) {
    return await dupeBusinessClient.get("/api/v1/campaigns/" + campaignId + "/slug").catch(error => {
        console.log(error);
    });
}

export async function archiveCampaign(campaignId) {
    return await dupeBusinessClient.delete("/api/v1/businesses/campaigns/" + campaignId).catch(error => {
        console.log(error);
    });
}

export async function restoreCampaign(campaignId) {
    return await dupeBusinessClient.post("/api/v1/businesses/campaigns/" + campaignId + "/restore").catch(error => {
        console.log(error);
    });
}

export async function updateCampaignOpenEnrollment(request) {
    return await dupeBusinessClient.post("/api/v1/campaigns/enrollment", request).catch(error => {
        console.log(error);
    });
}

export async function updateCampaignCreatorStatus(campaignId, creatorId, request) {
    return await dupeBusinessClient.post("/api/v1/campaigns/" + campaignId + "/creators/" + creatorId + "/status", request).catch(error => {
        console.log(error);
    });
}