import {StyleSheet, View, Text, Image, useWindowDimensions, TextInput} from "react-native-web";
import React, {useEffect, useState} from "react";
import Button from "./Button";
import {createPaymentMethod, getStripeIntent, getSubscriptionPlan, registerBusiness} from "../services/BusinessService";
import {loadStripe} from "@stripe/stripe-js";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useNavigate, useSearchParams} from "react-router-dom";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const BizRegistration = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [stage, setStage] = useState("NAME");
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState({})
    const [subscriptionPlan, setSubscriptionPlan] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();


    function advance() {
        if (stage === "NAME") {
            if (name == null || name == "") {
                setNameError("Please enter a valid business name")
            } else {
                setStage("INVITE");
                setNameError(null);
            }
        } else if (stage === "INVITE") {
            if (email == null || email == "") {
                setEmailError("Please enter a valid email")
            } else {
                setStage("CREDIT_CARD");
                setEmailError(null);
            }
        }
    }

    function previous() {
        if (stage === "CREDIT_CARD") {
            setStage("INVITE");
        } else if (stage === "INVITE") {
            setStage("NAME");
        }
    }

    function updateCard() {
        if (!isLoading) {
            setIsLoading(true);
            let req = {};
            req.name = name;
            req.email = email;
            req.intent = options;
            req.subscriptionPlanId = subscriptionPlan.subscriptionPlanId
            registerBusiness(req)
                .then(resp => {
                    navigate('/login');
                })
                .catch(() => {
                    setIsLoading(false);
                })
        }
    }

    useEffect(()=>{
        getSubscriptionPlan(searchParams.get('subscription'))
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setSubscriptionPlan(resp.data);
                }
            })
        getStripeIntent()
            .then(resp => {
                if (resp.status === 200 && resp.data && resp.data.client_secret) {
                    setOptions({
                        clientSecret: resp.data.client_secret,
                        id: resp.data.id
                    });
                }
            })
    }, []);

    return(
        <View style={styles.container}>
            {width > 1000 && <View style={{flex: 1, width: "60%", height: "100%"}}>
                <Image
                    source={{uri: "https://dupe-photos.s3.amazonaws.com/Login_Return.jpg"}}
                    style={{width: "100%", height: "100%"}}
                />
            </View>}
            <View style={{alignItems: "center", width: "40%"}}>
                <Image
                    style={styles.loginLogo}
                    source={require("../icons/Dupe_Logo_Blue.png")}
                    resizeMode="contain"
                    resizeMethod="resize"
                />
                <Text style={styles.boldBlueText}>Subscribing to {subscriptionPlan.name} for ${subscriptionPlan.price} a {subscriptionPlan.interval}</Text>
                {stage === "NAME" && (
                    <View>
                        <Text style={styles.inputHeaderText}>What is the name of your business?</Text>
                        {nameError && (
                            <Text style={styles.errorHeaderText}>{nameError}</Text>
                        )}
                        <View style={styles.inputView}>
                            <TextInput
                                style={styles.inputText}
                                placeholder="Business Name"
                                placeholderTextColor="white"
                                selectionColor="white"
                                autoCapitalize="none"
                                value={name}
                                onChangeText={text => setName(text)}
                            />
                        </View>
                    </View>
                )}
                {stage === "INVITE" && (
                    <View>
                        <Text style={styles.inputHeaderText}>Who all should be invited?</Text>
                        {emailError && (
                            <Text style={styles.errorHeaderText}>{emailError}</Text>
                        )}
                        <View style={styles.inputView}>
                            <TextInput
                                style={styles.inputText}
                                placeholder="Email"
                                placeholderTextColor="white"
                                selectionColor="white"
                                autoCapitalize="none"
                                value={email}
                                onChangeText={text => setEmail(text)}
                            />
                        </View>
                    </View>
                )}
                {stage === "CREDIT_CARD" && options.clientSecret && (
                    <View>
                        <Elements stripe={stripePromise} options={options}>
                            <StripeForm updateCard={updateCard} closeModal={previous}/>
                        </Elements>
                    </View>
                )}
                {stage !== "CREDIT_CARD" && (
                    <View>
                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                            <Button text="Previous" onPress={previous}  variant='secondary' style={{marginRight: 10, marginTop: 10}} />
                            <Button text={"Next"} onPress={advance} style={{marginTop: 10}}/>
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
}

const StripeForm = ({updateCard, closeModal}) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: "https://business.dupephotos.com/account",
            },
            redirect: "if_required",
        });

        if (error) {
            console.error(error);
            // handleError();
        } else {
            updateCard();
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                <Button text="Cancel" onPress={closeModal} variant='secondary' style={{marginTop: 10, marginRight: 10}}/>
                <Button text="Submit" onPress={handleSubmit} style={{marginTop: 10}}/>
            </View>
        </form>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f8f7f4',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "row"
    },
    loginLogo: {
        width: 150,
        height: 100,
        objectFit: 'contain',
        imageRendering: 'auto'
    },
    inputView: {
        position: 'relative',
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    },
    inputText: {
        width: 350,
        height: 50,
        flex: 1,
        color: "white",
        fontFamily: "'Lato', sans-serif",
        border: "none",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 25,
        backgroundColor: "#737373",
        outlineWidth: 0,
        fontSize: 16,
        WebkitBoxShadow: '0 0 0px 1000px #236fea inset',
        WebkitTextFillColor: 'white',
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0px 1000px #236fea inset !important',
            WebkitTextFillColor: 'white !important',
            caretColor: 'white !important',
        },
        '&:-webkit-credentials-auto-fill-button': {
            fontFamily: "'Lato', sans-serif !important",
            fontSize: '16px !important',
        },
        '&::-webkit-credentials-auto-fill-button:hover': {
            fontFamily: "'Lato', sans-serif !important",
            fontSize: '16px !important',
        },
        '&:-internal-autofill-selected': {
            fontFamily: "'Lato', sans-serif !important",
            fontSize: '16px !important',
        },
    },
    boldBlueText: {
        color: '#396de0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 20,
        fontWeight: 700,
        marginBottom: 20,
    },
    inputHeaderText: {
        fontFamily: "'lato', sans-serif",
        color: 'black',
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 5,
    },
    errorHeaderText: {
        fontFamily: "'lato', sans-serif",
        color: '#e03939',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 5,
    },
});

export default BizRegistration