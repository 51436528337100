import './App.css';
import {Routes, Route, Navigate} from "react-router-dom";
import {View} from "react-native-web";
import Login from "./components/Login";
import React from "react";
import SideBar from "./components/SideBar";
import CreatorSearch from "./components/CreatorSearch";
import Account from "./components/Account";
import AccessToken from "./components/AccessToken";
import {Amplify} from "aws-amplify";
import awsconfig from './aws-exports';
import Grid from "./components/Grid";
import BrandHub from "./components/BrandHub";
import Outreach from "./components/Outreach";
import Campaign from "./components/Campaign";
import ContentHub from "./components/ContentHub";
import EditCampaign from "./components/EditCampaign";
import Message from "./components/Message";
import Join from "./components/Join";
import {Hub} from "aws-amplify/utils";
import {useNavigate} from "react-router-dom";
import Browse from "./components/Browse";
import BizRegistration from "./components/BizRegistration";

export default function App() {
    const navigate = useNavigate();

    const isLocalhost = Boolean(
        window.location.hostname === 'localhost' ||
          // [::1] is the IPv6 localhost address.
          window.location.hostname === '[::1]' ||
          // 127.0.0.1/8 is considered localhost for IPv4.
          window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
          )
      );

    awsconfig.oauth = {
        domain: "auth.dupephotos.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: isLocalhost ? "http://localhost:3000/home/" : "https://business.dupephotos.com/home/",
        redirectSignOut: isLocalhost ? "http://localhost:3000/logout" : "https://business.dupephotos.com/logout",
        responseType: "code"
    };
    Amplify.configure(awsconfig);

    Hub.listen('auth', (data) => {
        console.log("data", data);
        switch (data.payload.event) {
            case "signedOut":
            case "tokenRefresh_failure":
                console.log("signed out");
                navigate("/login");
                break;
            default:
                break;
        }
    });


  return (
      <View style={{height: '100vh', backgroundColor: '#f1f1f1'}}>
        <Routes>
            <Route path="/" element={<SideBar/>}>
                <Route path="home" element={<BrandHub/>} />
                <Route path="browse" element={<Browse/>} />
                <Route path="creators/search" element={<CreatorSearch/>} />
                <Route path="account" element={<Account/>} />
                <Route path="account/callback" element={<AccessToken/>} />
                <Route path="grid" element={<Grid/>} />
                <Route path="outreach" element={<Outreach/>} />
                <Route path="campaigns/:campaignSlug/edit" element={<EditCampaign/>} />
                <Route path="campaigns/create" element={<EditCampaign/>} />
                <Route path="campaigns/:campaignSlug" element={<Campaign/>} />
                <Route path="content" element={<ContentHub/>} />
                <Route path="messages" element={<Message/>} />
                <Route path="" element={<Navigate to="/home" />} />
            </Route>
            <Route path="/register" element={<BizRegistration />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/join/:inviteId" element={<Join />}/>
        </Routes>
    </View>
  );
}
